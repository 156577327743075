type ProductsType = {
  [key: string]: {
    name: string;
    senders: {
      [key: string]: string;
    };
  };
}

const Products: ProductsType = {
  WellHello: {
    name: 'WellHello',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'billing_sendgrid' : 'wh_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'confirmation_sendgrid' : 'wh_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'confirmation_sendgrid' : 'wh_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'messages_sendgrid' : 'wh_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'account_sendgrid' : 'wh_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'messages_sendgrid' : 'wh_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'messages_sendgrid' : 'wh_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'messages_sendgrid' : 'wh_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'account_sendgrid' : 'wh_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'confirmation_sendgrid' : 'wh_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'messages_sendgrid' : 'wh_staging_sendgrid'
    }
  },
  GetFlirty: {
    name: 'GetFlirty',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'billing_sendgrid' : 'sendgrid_confirmation',
      Cancellation: process.env.NODE_ENV === 'production' ? 'confirmation_sendgrid' : 'sendgrid_confirmation',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'confirmation_sendgrid' : 'sendgrid_confirmation',
      Flirt: process.env.NODE_ENV === 'production' ? 'message_sendgrid' : 'sendgrid_messages',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'account_sendgrid' : 'sendgrid_messages',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'message_sendgrid' : 'sendgrid_messages',
      NewMessage: process.env.NODE_ENV === 'production' ? 'message_sendgrid' : 'sendgrid_messages',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'message_sendgrid' : 'sendgrid_messages',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'account_sendgrid' : 'sendgrid_confirmation',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'confirmation_sendgrid' : 'sendgrid_confirmation',
      Friend: process.env.NODE_ENV === 'production' ? 'message_sendgrid' : 'sendgrid_messages'
    }
  },
  YourSecretHookup: {
    name: 'YourSecretHookup',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'ysh_billing_sendgrid' : 'ysh_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'ysh_confirm_sendgrid' : 'ysh_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'ysh_confirm_sendgrid' : 'ysh_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'ysh_messages_sendgrid' : 'ysh_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'ysh_confirm_sendgrid' : 'ysh_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'ysh_messages_sendgrid' : 'ysh_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'ysh_messages_sendgrid' : 'ysh_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'ysh_messages_sendgrid' : 'ysh_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'ysh_confirm_sendgrid' : 'ysh_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'ysh_confirm_sendgrid' : 'ysh_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'ysh_messages_sendgrid' : 'ysh_staging_sendgrid'
    }
  },
  ComeWithYou: {
    name: 'ComeWithYou',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'cwy_billing_sendgrid' : 'cwy_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'cwy_confirm_sendgrid' : 'cwy_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'cwy_confirm_sendgrid' : 'cwy_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'cwy_messages_sendgrid' : 'cwy_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'cwy_confirm_sendgrid' : 'cwy_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'cwy_messages_sendgrid' : 'cwy_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'cwy_messages_sendgrid' : 'cwy_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'cwy_messages_sendgrid' : 'cwy_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'cwy_confirm_sendgrid' : 'cwy_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'cwy_confirm_sendgrid' : 'cwy_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'cwy_messages_sendgrid' : 'cwy_staging_sendgrid'
    }
  },
  HushLove: {
    name: 'HushLove',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'hl_billing_sendgrid' : 'hl_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'hl_confirm_sendgrid' : 'hl_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'hl_confirm_sendgrid' : 'hl_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'hl_messages_sendgrid' : 'hl_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'hl_confirm_sendgrid' : 'hl_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'hl_messages_sendgrid' : 'hl_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'hl_messages_sendgrid' : 'hl_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'hl_messages_sendgrid' : 'hl_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'hl_confirm_sendgrid' : 'hl_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'hl_confirm_sendgrid' : 'hl_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'hl_messages_sendgrid' : 'hl_staging_sendgrid'
    }
  },
  MyHornySingle: {
    name: 'MyHornySingle',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'wl_billing_sendgrid' : 'wl_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid'
    }
  },
  LustSeek: {
    name: 'LustSeek',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'wl_billing_sendgrid' : 'wl_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid'
    }
  },
  SideChicks: {
    name: 'SideChicks',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'wl_billing_sendgrid' : 'wl_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid'
    }
  },
  YourLocalHookup: {
    name: 'YourLocalHookup',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'wl_billing_sendgrid' : 'wl_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid'
    }
  },
  KinkySwipe: {
    name: 'KinkySwipe',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'wl_billing_sendgrid' : 'wl_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid'
    }
  },
  ComeWithDaddy: {
    name: 'ComeWithDaddy',
    senders: {
      AfterPay: process.env.NODE_ENV === 'production' ? 'wl_billing_sendgrid' : 'wl_staging_sendgrid',
      Cancellation: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      ConfirmEmailNopass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Flirt: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      ForgotPass: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      FriendAccepted: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      NewMessage: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      PhotoRemoved: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid',
      TemporaryAccess: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      UserDeleted: process.env.NODE_ENV === 'production' ? 'wl_confirm_sendgrid' : 'wl_staging_sendgrid',
      Friend: process.env.NODE_ENV === 'production' ? 'wl_messages_sendgrid' : 'wl_staging_sendgrid'
    }
  },
  Qkkie: {
    name: 'Qkkie',
    senders: {
      DatingUserRegistered: process.env.NODE_ENV === 'production' ? 'qkkie_prod_sendgrid' : 'qkkie_staging_sendgrid'
    }
  }
}

if (process.env.NODE_ENV !== 'production') {
  Products.Testing = {
    name: 'Testing',
    senders: {
      AfterPay: 'default',
      Cancellation: 'default',
      ConfirmEmailNopass: 'default',
      Flirt: 'default',
      ForgotPass: 'default',
      FriendAccepted: 'default',
      NewMessage: 'default',
      PhotoRemoved: 'default',
      TemporaryAccess: 'default',
      UserDeleted: 'default',
      Friend: 'default'
    }
  }
}

export { Products }
