import { MessageTypeIdentificatorType } from '../value-objects/MessageTypes'

export type MailerTemplateType = {
  name: string;
  trustabilityLevel: number;
  useInterval: boolean;
  sender: string;
  provider: {
    name: string;
    subject: string;
    text: string;
    html: string;
  };
}

export class Template {
  public name: string;
  public messageType: MessageTypeIdentificatorType;
  public trustabilityLevel: number;
  public useInterval: boolean;
  public sender: string;
  public providerName: string;
  public subject: string;
  public html: string;
  public text: string;

  constructor (
    name: string,
    messageType: MessageTypeIdentificatorType,
    trustabilityLevel: number,
    useInterval: boolean,
    sender: string,
    providerName: string,
    subject: string,
    text: string,
    html: string
  ) {
    if (subject === '' || html === '' || name === '') {
      throw new Error('Please fill in all required* fields')
      return
    }

    if (name.includes('.') || name.includes('|') || name.includes('/') || name.includes(' ')) {
      throw new Error('Name must not contain special characters')
      return
    }

    this.name = name
    this.messageType = messageType
    this.trustabilityLevel = trustabilityLevel
    this.useInterval = useInterval
    this.sender = sender
    this.providerName = providerName
    this.subject = subject
    this.text = text
    this.html = html
  }

  public static createFromMailerResponse (mailerTemplate: MailerTemplateType): Template {
    const name = mailerTemplate.name.split('.')[0]
    const messageType = mailerTemplate.name.split('.')[1] as MessageTypeIdentificatorType

    return new Template(
      name,
      messageType,
      mailerTemplate.trustabilityLevel,
      mailerTemplate.useInterval,
      mailerTemplate.sender,
      mailerTemplate.provider.name,
      mailerTemplate.provider.subject,
      mailerTemplate.provider.text,
      mailerTemplate.provider.html
    )
  }

  public toMailerRequest (): MailerTemplateType {
    return {
      name: this.getMailerName(),
      trustabilityLevel: this.trustabilityLevel,
      useInterval: this.useInterval,
      sender: this.sender,
      provider: {
        name: this.providerName,
        subject: this.subject,
        text: this.text,
        html: this.html
      }
    }
  }

  public getMailerName (): string {
    return this.name + (this.messageType ? '.' + this.messageType : '')
  }
}
