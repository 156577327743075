import axios, { AxiosResponse } from 'axios'
import { ActionContext, Module } from 'vuex'
import { ApiSplitTestTemplate, SplitTest } from '@/domain/entities/SplitTest'
import { Template } from '@/domain/entities/Template'

export type SplitTestModuleState = {
  splitTest: SplitTest | null;
}

const baseSplitTestUrl = process.env.VUE_APP_API_URL + '/splitTest'

export const SplitTestModule: Module<SplitTestModuleState, {}> = {
  state: {
    splitTest: null
  },
  mutations: {
    storeSplitTest: (state: SplitTestModuleState, { splitTest, context }) => {
      const apiSplitTestTemplateArray = splitTest.templates
        .map((apiSplitTestTemplate: ApiSplitTestTemplate) => {
          const template = context.rootState.template.templates.find((template: Template) => {
            return template.getMailerName() === apiSplitTestTemplate.name
          })

          return {
            template: template,
            traffic: apiSplitTestTemplate.traffic
          }
        })

      state.splitTest = new SplitTest(
        splitTest.product,
        splitTest.trigger,
        apiSplitTestTemplateArray
      )
    }
  },
  actions: {
    getSplitTest: function (context: ActionContext<any, any>, params) {
      return axios
        .get(baseSplitTestUrl, {
          params: params,
          headers: {
            Authorization: context.rootState.auth.googleAuthResponse.id_token,
            'X-Authorization': context.rootState.product
          }
        })
        .then((response: AxiosResponse) => {
          if (context.rootState.template.templates.length === 0) {
            return context.dispatch('getTemplates').then(() => {
              context.commit('storeSplitTest', { splitTest: response.data.splitTest, context: context })
            })
          }

          return context.commit('storeSplitTest', { splitTest: response.data.splitTest, context: context })
        })
    },
    updateSplitTest: function (context: ActionContext<any, any>, params: any) {
      return axios
        .put(
          baseSplitTestUrl,
          params,
          {
            headers: {
              Authorization: context.rootState.auth.googleAuthResponse.id_token,
              'X-Authorization': context.rootState.product
            }
          }
        )
    }
  },
  getters: {}
}

export default SplitTestModule
