import axios, { AxiosResponse } from 'axios'
import { ActionContext, Module } from 'vuex'
import { MailerTemplateType, Template } from '@/domain/entities/Template'

export type TemplateModuleState = {
  templates: Template[];
}

const baseTemplatesUrl = process.env.VUE_APP_API_URL + '/templates'

export const TemplateModule: Module<TemplateModuleState, {}> = {
  state: {
    templates: []
  },
  mutations: {
    storeTemplates: (state: TemplateModuleState, templates: MailerTemplateType[]) => {
      state.templates = templates.reduce(
        (templates: Template[], mailerTemplate: MailerTemplateType) => {
          try {
            templates.push(Template.createFromMailerResponse(mailerTemplate))
          } catch (e) {
          }

          return templates
        },
        []
      )
    }
  },
  actions: {
    getTemplates: function (context: ActionContext<any, any>) {
      return axios
        .get(baseTemplatesUrl, {
          headers: {
            Authorization: context.rootState.auth.googleAuthResponse.id_token,
            'X-Authorization': context.rootState.product
          }
        })
        .then((response: AxiosResponse) => {
          context.commit('storeTemplates', response.data)
        })
    },
    sendTest: function (context: ActionContext<any, any>, params) {
      return axios
        .put(
          process.env.VUE_APP_API_URL + '/emailUsers/' + params.emailAddress,
          {
            verified: true,
            confirmed: true
          },
          {
            headers: {
              Authorization: context.rootState.auth.googleAuthResponse.id_token,
              'X-Authorization': context.rootState.product
            }
          }
        )
        .catch(error => console.error(error))
        .then(() => {
          return axios
            .post(
              process.env.VUE_APP_API_URL + '/sendEmail',
              {
                emailAddress: params.emailAddress,
                templateName: params.template.getMailerName()
              },
              {
                headers: {
                  Authorization: context.rootState.auth.googleAuthResponse.id_token,
                  'X-Authorization': context.rootState.product
                }
              }
            )
        })
    },
    deleteTemplate: function (context: ActionContext<any, any>, params) {
      return axios
        .delete(
          process.env.VUE_APP_API_URL + '/templates/' + params.template.getMailerName(),
          {
            headers: {
              Authorization: context.rootState.auth.googleAuthResponse.id_token,
              'X-Authorization': context.rootState.product
            }
          }
        )
    },
    createTemplate: function (context: ActionContext<any, any>, params: MailerTemplateType) {
      return axios
        .post(
          process.env.VUE_APP_API_URL + '/templates',
          params,
          {
            headers: {
              Authorization: context.rootState.auth.googleAuthResponse.id_token,
              'X-Authorization': context.rootState.product
            }
          }
        )
    },
    updateTemplate: function (context: ActionContext<any, any>, params: MailerTemplateType) {
      return axios
        .put(
          process.env.VUE_APP_API_URL + '/templates/' + params.name,
          params,
          {
            headers: {
              Authorization: context.rootState.auth.googleAuthResponse.id_token,
              'X-Authorization': context.rootState.product
            }
          }
        )
    }
  },
  getters: {}
}

export default TemplateModule
