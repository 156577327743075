import { MessageTypeIdentificatorType } from '@/domain/value-objects/MessageTypes'
import { Template } from '@/domain/entities/Template'

export type ApiSplitTestTemplate = {
  name: string;
  traffic: number;
};

export type ApiSplitTest = {
  product: string;
  trigger: MessageTypeIdentificatorType;
  templates: ApiSplitTestTemplate[];
};

export type SplitTestTemplate = {
  template: Template;
  traffic: number;
};

export class SplitTest {
  public product: string;
  public trigger: MessageTypeIdentificatorType;
  public templates: SplitTestTemplate[];

  constructor (
    product: string,
    trigger: MessageTypeIdentificatorType,
    templates: SplitTestTemplate[]
  ) {
    this.product = product
    this.trigger = trigger
    this.templates = templates
  }
}
