import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import TemplateModule, { TemplateModuleState } from './modules/template/Template.module'
import { Products } from '@/domain/value-objects/Products'
import AuthenticationModule, { AuthenticationModuleState } from '@/store/modules/auth/Authentication.module'
import VuexPersistence from 'vuex-persist'
import { SplitTestModule, SplitTestModuleState } from '@/store/modules/trigger/SplitTestModule'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: (state: RootState) => {
    return {
      product: state.product,
      auth: state.auth
    }
  }
})

export interface RootState {
  product: string;
  template: TemplateModuleState;
  splitTest: SplitTestModuleState;
  auth: AuthenticationModuleState;
  hamburger: boolean;
}

export default new Vuex.Store<RootState>({
  state: {
    product: Products.WellHello.name,
    template: {
      templates: []
    },
    auth: {
      googleAuthResponse: null
    },
    splitTest: {
      splitTest: null
    },
    hamburger: false
  },
  mutations: {
    setProduct (state, payload) {
      state.product = payload.product
    },
    hamburgerSwitch (state) {
      state.hamburger = !state.hamburger
    }
  },
  actions: {
  },
  modules: {
    auth: AuthenticationModule,
    template: TemplateModule,
    splitTest: SplitTestModule
  },
  plugins: [
    vuexLocal.plugin,
    // vuexAuth.plugin,
    function updateTemplatesOnProductChange (store: Store<RootState>) {
      store.subscribe((mutation) => {
        if (mutation.type !== 'setProduct') {
          return
        }

        store.dispatch('getTemplates')
      })
    }
  ]
})
