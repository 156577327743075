import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
const Login = () => import('@/views/login/Login.vue').then(m => m.default)
const IndexTemplate = () => import('../views/template/index/IndexTemplate.vue').then(m => m.default)
const CreateTemplate = () => import('@/views/template/create/CreateTemplate.vue').then(m => m.default)
const UpdateTemplate = () => import('@/views/template/update/UpdateTemplate.vue').then(m => m.default)
const CloneTemplate = () => import('@/views/template/clone/CloneTemplate.vue').then(m => m.default)
const TriggerIndex = () => import('../views/trigger/index/TriggerIndex.vue').then(m => m.default)
const TriggerUpdate = () => import('../views/trigger/update/TriggerUpdate.vue').then(m => m.default)
import { requireAuth } from '@/router/AuthMiddleware'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'IndexTemplate',
    component: IndexTemplate,
    beforeEnter: requireAuth
  },
  {
    path: '/templates/index',
    name: 'IndexTemplate',
    component: IndexTemplate,
    beforeEnter: requireAuth
  },
  {
    path: '/templates/create',
    name: 'CreateTemplate',
    component: CreateTemplate,
    beforeEnter: requireAuth
  },
  {
    path: '/templates/update/:templateName',
    name: 'UpdateTemplate',
    component: UpdateTemplate,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/templates/clone/:templateName',
    name: 'CloneTemplate',
    component: CloneTemplate,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/triggers/index',
    name: 'TriggerIndex',
    component: TriggerIndex,
    beforeEnter: requireAuth
  },
  {
    path: '/triggers/update/:triggerName',
    name: 'TriggerUpdate',
    component: TriggerUpdate,
    props: true,
    beforeEnter: requireAuth
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
