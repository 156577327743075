import store from '../store'

export const requireAuth = (to: any, from: any, next: any) => {
  if (!store.getters.isUserAuthenticated) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }

  next()
}
