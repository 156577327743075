import { Module } from 'vuex'

export type AuthenticationModuleState = {
  googleAuthResponse: GoogleAuthResponse | null;
}

export type GoogleAuthResponse = {
  expires_at: number;
  expires_in: number;
  first_issued_at: number;
  id_token: string;
  idpId: string;
  login_hint: string;
  token_type: string;
}

export const AuthenticationModule: Module<AuthenticationModuleState, {}> = {
  state: {
    googleAuthResponse: null
  },
  mutations: {
    storeGoogleUser: (state: AuthenticationModuleState, googleAuthResponse: GoogleAuthResponse) => {
      state.googleAuthResponse = googleAuthResponse
    },
    removeGoogleUser: (state: AuthenticationModuleState) => {
      state.googleAuthResponse = null
    }
  },
  actions: {},
  getters: {
    isUserAuthenticated: function (state) {
      if (state.googleAuthResponse === null) {
        return false
      }

      if (state.googleAuthResponse.expires_at <= new Date().getTime()) {
        return false
      }

      return true
    }
  }
}

export default AuthenticationModule
